import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Depreciation } from '../models/depreciation.model';
import { Configs } from 'src/app/_shared/configs.model';

export const closeDialog = createAction('[Depreciation/API] Close Depreciation Dialog');

export const cancelDialog = createAction('[Depreciation/API] Cancel Depreciation Dialog');

export const addDialog = createAction(
  '[Depreciation/API] Add Depreciation Dialog',
  props<{ initData?: any }>()
);

export const editDialog = createAction(
  '[Depreciation/API] Edit Depreciation Dialog',
  props<{ uuid: string }>()
);

export const loadById = createAction(
  '[Depreciation/API] Load Depreciation By Id',
  props<{ uuid: string }>()
);

export const loadByIdSuccess = createAction(
  '[Depreciation/API] Load Depreciation By Id Success',
  props<{ payload: Depreciation.Model }>()
);

export const loadByIdFailure = createAction(
  '[Depreciation/API] Load Depreciation By Id Failure',
  props<{ errorMsg: any }>()
);

export const loadUsedDeprDates = createAction('[Depreciation/API] Load Used Depreciation Dates');

export const loadUsedDeprDatesSuccess = createAction(
  '[Depreciation/API] Load Used Depreciation Dates Success',
  props<{ dates: string[] }>()
);

export const loadUsedDeprDatesFailure = createAction(
  '[Depreciation/API] Load Used Depreciation Dates Failure',
  props<{ errorMsg: any }>()
);

export const loadByIdForDialog = createAction(
  '[Depreciation/API] Load Depreciation By Id For Dialog',
  props<{ uuid: string }>()
);

export const loadByIdForDialogSuccess = createAction(
  '[Depreciation/API] Load Depreciation By Id For Dialog Success',
  props<{ payload: Depreciation.Model }>()
);

export const loadByIdForDialogFailure = createAction(
  '[Depreciation/API] Load Depreciation By Id For Dialog Failure',
  props<{ errorMsg: any }>()
);

export const load = createAction(
  '[Depreciation/API] Load Depreciations',
  props<Configs.GetQueryParams>()
);

export const loadSuccess = createAction(
  '[Depreciation/API] Load Depreciations Success',
  props<{ payload: Configs.PaginationModel<Depreciation.Model> }>()
);

export const loadFailure = createAction(
  '[Depreciation/API] Load Depreciations Failure',
  props<{ error: any }>()
);

export const save = createAction('[Depreciation/API] Save Depreciation', props<{ uuid: string }>());

export const saveSuccess = createAction(
  '[Depreciation/API] Save Depreciation Success',
  props<{ payload: any }>()
);

export const saveFailure = createAction(
  '[Depreciation/API] Save Depreciation Failure',
  props<{ error: any }>()
);

export const cancel = createAction('[Depreciation/API] Cancel Depreciations');

export const cancelSuccess = createAction('[Depreciation/API] Cancel Depreciation Success');

export const cancelFailure = createAction(
  '[Depreciation/API] Cancel Depreciation Failure',
  props<{ error: any }>()
);

export const add = createAction(
  '[Depreciation/API] Add Depreciation',
  props<{ payload: { month: string; year: string } }>()
);

export const addSuccess = createAction(
  '[Depreciation/API] Add Depreciation Success',
  props<{ payload: Depreciation.Model }>()
);

export const addFailure = createAction(
  '[Depreciation/API] Add Depreciation Failure',
  props<{ error: any }>()
);

export const update = createAction(
  '[Depreciation/API] Update Depreciations',
  props<{ payload: Depreciation.Model }>()
);

export const updateSuccess = createAction(
  '[Depreciation/API] Update Depreciation Success',
  props<{ payload: Update<Depreciation.Model> }>()
);

export const updateFailure = createAction(
  '[Depreciation/API] Update Depreciation Failure',
  props<{ error: any }>()
);

export const deleteItem = createAction(
  '[Depreciation/API] Delete Depreciation',
  props<{ uuid: string }>()
);

export const deleteItemSuccess = createAction(
  '[Depreciation/API] Delete Depreciation Success',
  props<{ uuid: string }>()
);

export const deleteItemFailure = createAction(
  '[Depreciation/API] Delete Depreciation Failure',
  props<{ error: any }>()
);

export const deleteLastItem = createAction('[Depreciation/API] Delete Last Depreciation');

export const deleteLastItemSuccess = createAction(
  '[Depreciation/API] Delete Last Depreciation Success',
  props<{ uuid: string }>()
);

export const deleteLastItemFailure = createAction(
  '[Depreciation/API] Delete Last Depreciation Failure',
  props<{ error: any }>()
);

export const setCurrentDeprId = createAction(
  '[Depreciation/API] Set Current Depr Id',
  props<{ uuid: string }>()
);

export const resetDeprId = createAction('[Depreciation/API] Reset Depr Id');
